import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Divider } from '../components/Divider.tsx';
import { theme } from '../constants.ts';
import { Pricing } from '../components/Pricing.tsx';
import { PageLayout, Content, Title } from '../components/PageLayout.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout('Pricing');
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <Pricing mdxType="Pricing" />
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      